<template>
  <b-container>
    <b-row class="mb-6">
      <b-col>
        <h1>Bienvenido {{ currentUser.attributes.name }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols md="4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 15% auto; background-image: url(media/svg/shapes/abstract-2.svg);"
        >
          <div class="card-body">
            <a
              href="#"
              class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
              >Progreso</a
            >
            <div class="mt-10">
              <CHART :percentages="['done']" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols md="4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 15% auto; background-image: url(media/svg/shapes/abstract-2.svg)"
        >
          <div class="card-body">
            <a
              href="#"
              class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
              @click="showTasksUser"
              >Tareas Pendientes</a
            >
            <div class="mt-10">
              <TASKS :count="this.totalTasks" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols md="4">
        <div
          class="card card-custom bgi-no-repeat gutter-b card-stretch"
          style="background-position: right top; background-size: 15% auto; background-image: url(media/svg/shapes/abstract-2.svg)"
        >
          <div class="card-body">
            <router-link :to="{ name: 'formations' }">
              <a
                href="#"
                class="card-title font-weight-bold text-muted text-hover-primary font-size-h5"
                >Formaciones Recientes</a
              >
            </router-link>

            <div class="mt-10">
              <FORMATIONS />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import CHART from "@/components/dashboard/task-chart-component";
import TASKS from "@/components/dashboard/task-component";
import FORMATIONS from "@/components/dashboard/formation-component";

export default {
  name: "dashboard",
  components: {
    TASKS,
    FORMATIONS,
    CHART
  },
  data() {
    return {
      totalTasks: 6,
      codesStatus: [200, 201],
      roles: [1, 2] // 1: socio, 2: lider, 3:empleado
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getStatus",
      "isAuthenticated",
      "isFirstLogin"
    ])
  },
  beforeMount() {
    const payload = {
      userId: this.currentUser.id
    };
    this.$store.dispatch("listFormations");
    if (this.roles.indexOf(this.currentUser.attributes.rol_id) != -1) {
      this.totalTasks = 99;
      this.$store.dispatch("listAllTasks", payload);
    } else {
      this.$store.dispatch("listTasksUser", payload);
    }
  },
  methods: {
    showTasksUser() {
      const payload = {
        userId: this.currentUser.id
      };
      this.$store
        .dispatch("listTasksUser", payload)
        .then(response => {
          if (this.codeStatus.indexOf(response.status) != -1) {
            this.$router.push({
              name: "showTasksUser",
              params: {
                username: this.currentUser.attributes.name
              }
            });
          }
        })
        .catch(e => console.error({ e }));
    }
  }
};
</script>
